export const setUtmToLS = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

  utmKeys.forEach((key) => {
    if (urlParams.has(key)) {
      let value = urlParams.get(key).replaceAll(' ', '_').replaceAll('+', '_');

      // Only replace dashes for 'utm_source'
      if (key === 'utm_source') {
        value = value.replaceAll('-', '_').toLowerCase();
      }

      localStorage.setItem(key, value);
    }
    // else if(localStorage.getItem(key)) {
    //   localStorage.removeItem(key);
    // }
  });
};
export const updatePhoneAfterUtm = () => {
  const value = localStorage.getItem('utm_source');

  if (value?.toLocaleLowerCase() === 'google') {
    const isThankYou = document.body.classList.contains('thank-you');
    setTimeout(() => {
      const links = document.querySelectorAll('*[href="tel:+18444470007"]');
      links.forEach((link) => {
        // +1 (813) 428-9500
        link.setAttribute('href', 'tel:+18134289500');
        const spanElement = link.querySelector(`.btn__content > span ${isThankYou ? '> span:nth-child(2)' : ''}`);
        const text = spanElement?.textContent || link.textContent;
        const sourceString = '844-447-0007';
        const replacedString = '813-428-9500';

        if (text?.includes(sourceString)) {
          (spanElement || link).textContent = (spanElement || link).textContent.replace(sourceString, replacedString);
        }
      });
    }, 1000);
  }
};

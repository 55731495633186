import { headerHeight } from 'src/assets/js/helpers/constants';
import { getCurrentBreakpoint, pasteByInsertPosition } from 'src/assets/js/helpers/helpers';

export const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const addHiddenInput = ({ container, name }) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'hidden');
  input.setAttribute('name', name);
  pasteByInsertPosition({ parentElement: container, child: input });

  return input;
};

export function sortByField(field) {
  return (a, b) => (a[field] > b[field] ? 1 : -1);
}

/**
 * @param {String} HTML representing a single element.
 * @param {Boolean} flag representing whether or not to trim input whitespace, defaults to true.
 * @return {Element | HTMLCollection | null}
 */
export function fromHTML(html, trim = true) {
  // Process the HTML string.
  html = trim ? html.trim() : html;
  if (!html) return null;

  // Then set up a new template element.
  const template = document.createElement('template');
  template.innerHTML = html;
  const result = template.content.children;

  // Then return either an HTMLElement or HTMLCollection,
  // based on whether the input HTML had one or more roots.
  if (result.length === 1) return result[0];
  return result;
}

export function animateValue({ element, start, end, duration, symbol }) {
  if (element) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      let progress_value = Math.floor(progress * (end - start) + start);
      if (symbol == '$') {
        progress_value = symbol + new Intl.NumberFormat('en-US').format(progress_value);
      } else {
        progress_value = symbol + progress_value;
      }

      element.innerHTML = progress_value + '*';
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  } else {
    console.warn('animateValue obj not found');
  }
}

const initSetHigherHeights = ({ blockArrayElements }) => {
  let maxHeight = 0;
  blockArrayElements.forEach((blockElement) => {
    blockElement.style.removeProperty('min-height');
    if (maxHeight < blockElement.offsetHeight) {
      maxHeight = blockElement.offsetHeight;
    }
  });
  blockArrayElements.forEach((blockElement) => (blockElement.style.minHeight = `${maxHeight}px`));
};

export const debouncedInitOnResize = async ({ initFunction, params, debounceTime = 500 }) => {
  const debouncedInit = debounce(initFunction, debounceTime);
  await initFunction(params);
  addEventListener('resize', () => debouncedInit(params));
};

export const setHigherHeights = (blockElements) => {
  if (blockElements && blockElements.length) {
    const blockArrayElements = [...blockElements];
    debouncedInitOnResize({
      initFunction: initSetHigherHeights,
      params: {
        blockArrayElements,
      },
      debounceTime: 10,
    });
  }
};
export const addedClassOnResize = ({ element, classes = 'dialog-common_resized', maxInnerWidth = 768 }) => {
  if (innerWidth < maxInnerWidth) {
    addEventListener('resize', () => {
      element.classList.add(classes);
    });
  }
};

export const setRedirectToElements = ({ elements }) => {
  if (elements && elements.length) {
    elements.forEach(({ selector, url, isHash, callback }) => {
      let handleClick;

      if (isHash) {
        const element = document.querySelector(url);

        handleClick = (e) => {
          e.preventDefault;
          const y = element.getBoundingClientRect().top + window.scrollY - headerHeight[getCurrentBreakpoint()];

          window.scroll({
            top: y,
            behavior: 'smooth',
          });
          callback && callback();

          return false;
        };
      } else {
        handleClick = (e) => {
          e.preventDefault;
          window.location = url;
          callback && callback();

          return false;
        };
      }
      const linkElements = document.querySelectorAll(selector);
      for (const linkElement of linkElements) {
        linkElement.onclick = handleClick;
      }
    });
  }
};
export const outerHeight = (elem) => {
  const curStyle = elem.currentStyle || window.getComputedStyle(elem);
  let outerHeight = elem.offsetHeight;
  outerHeight += parseInt(curStyle.marginTop);
  outerHeight += parseInt(curStyle.marginBottom);

  return outerHeight; //If you'd like to return the outerheight
};
export const initZoomButtons = ({ elements }) => {
  if (elements) {
    let zoom = 1;
    const zoomStep = 0.2;
    const zoomButtons = document.querySelector('.zoom-buttons');

    if (zoomButtons) {
      zoomButtons.style.display = 'flex';
      const zoomOut = zoomButtons.querySelector('.zoom-buttons__button_minus');
      const zoomIn = zoomButtons.querySelector('.zoom-buttons__button_plus');

      zoomIn.addEventListener('click', function () {
        zoom += zoomStep;
        elements.forEach((element) => (element.style.transform = 'scale(' + zoom + ')'));
      });
      zoomOut.addEventListener('click', function () {
        if (zoom > zoomStep) {
          zoom -= zoomStep;
          elements.forEach((element) => (element.style.transform = 'scale(' + zoom + ')'));
        }
      });
    }
  }
};

import axios from 'axios';
import reviewsTemplate from 'src/templates/layouts/sections/reviews/reviews.ejs';
import reviewsType2Template from 'src/templates/layouts/sections/reviews/reviewsType2.ejs';
import reviewTemplate from 'src/templates/layouts/sections/reviews/review.ejs';
import reviewType2Template from 'src/templates/layouts/sections/reviews/reviewType2.ejs';
import reviewNavTemplate from 'src/templates/layouts/sections/reviews/reviewsNavigation.ejs';
import reviewSlideTemplate from 'src/templates/layouts/sections/reviews/reviewsSlide.ejs';
import reviewStarTemplate from 'src/templates/components/reviewStars/reviewStar.ejs';
import reviewFilledStarTemplate from 'src/templates/components/reviewStars/reviewFilledStar.ejs';
import reviewStarsTemplate from 'src/templates/components/reviewStars/reviewStars.ejs';
// import Swiper JS + navigation
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import { debounce } from 'src/assets/js/utils/utils';

const initSlider = ({ reviewBlocks, reviews, reviewsSection, TrustScore, ReviewsCount }) => {
  let reviewNav = '';
  let reviewSlides = [];

  if (innerWidth < 768) {
    reviewSlides = reviewBlocks
      .map((reviewBlock) =>
        reviewSlideTemplate({
          reviewBlocks: reviewBlock,
        })
      )
      .join('');
  } else {
    reviewNav = reviewNavTemplate();
    for (let index = 0; index < reviews.length; index++) {
      const review = reviewBlocks[index];
      if (index % 2 === 0) {
        reviewSlides.push([review]);
      } else {
        reviewSlides[reviewSlides.length - 1].push(review);
      }
    }
    reviewSlides = reviewSlides
      .map((reviewSlideArr) =>
        reviewSlideTemplate({
          reviewBlocks: reviewSlideArr.join(''),
        })
      )
      .join('');
  }
  reviewsSection.innerHTML = reviewsTemplate({
    percentOfTrusted: Number.parseInt((TrustScore / 5) * 100, 10),
    reviewSlides,
    reviewsRate: TrustScore,
    reviewsCount: ReviewsCount,
    reviewNav,
  });

  // init Swiper:
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper(reviewsSection.querySelector('.swiper'), {
    // Default parameters
    // configure Swiper to use modules
    modules: [Navigation],
    navigation: {
      nextEl: reviewsSection.querySelector('.swiper-button-next'),
      prevEl: reviewsSection.querySelector('.swiper-button-prev'),
    },
    slidesPerView: 'auto',
    spaceBetween: 16,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
        spaceBetween: 12,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 4,
        spaceBetween: 12,
      },
      // when window width is >= 1440px
      1440: {
        slidesPerView: 5,
        spaceBetween: 16,
      },
      // when window width is >= 1920px
      1920: {
        slidesPerView: 6,
      },
    },
  });
};
export const getReviewStars = ({ classes, stars, isSecondType }) => {
  const starElements = [];
  for (let index = 1; index < 6; index++) {
    const diff = index - stars;

    starElements.push(
      (isSecondType ? reviewFilledStarTemplate : reviewStarTemplate)({
        width: index < stars ? 100 : Math.floor(diff) ? 0 : (1 - diff) * 100,
      })
    );
  }
  return reviewStarsTemplate({
    classes,
    starElements: starElements.join(''),
  });
};
const initReviewsContent = ({ reviews, reviewsSection, isSecondType }) => {
  const reviewBlocks = reviews
    .slice(0, 8)
    .map(({ reviewLink, title, text, stars }) => {
      const reviewStars = getReviewStars({
        stars,
        classes: 'trustpilot-review-2__stars-filled',
        isSecondType,
      });
      let rateText = 'Awful';

      if (stars > 4) {
        rateText = 'Excellent';
      } else if (stars > 3) {
        rateText = 'Very Good';
      } else if (stars > 2) {
        rateText = 'Not Bad';
      } else if (stars > 1) {
        rateText = 'Bad';
      }

      return reviewType2Template({
        reviewLink,
        rateText,
        stars: reviewStars,
        title,
        text,
      });
    })
    .join('');

  reviewsSection.innerHTML = reviewsType2Template({
    reviews: reviewBlocks,
  });
};
export const initReviews = async ({ url, reviews }) => {
  const reviewsSection = document.querySelector('section.trustpilot-reviews');
  if (reviewsSection) {
    try {
      const reviewsData = reviews || (await axios.get(url));

      if (reviewsData) {
        const { TrustScore, ReviewsCount, reviews } = reviewsData.data;
        const isSecondType = reviewsSection.dataset.type === '2';
        if (isSecondType) {
          initReviewsContent({ reviews, reviewsSection, isSecondType });
        } else {
          const reviewBlocks = reviews.map(({ reviewLink, title, text, clientName, createdAt, stars }) => {
            const reviewStars = getReviewStars({
              stars,
              classes: 'trustpilot-review__stars',
            });
            return reviewTemplate({
              reviewLink,
              stars: reviewStars,
              title,
              text,
              clientName,
              date: new Date(createdAt).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric' }),
            });
          });

          const params = {
            reviewBlocks,
            reviews,
            reviewsSection,
            TrustScore,
            ReviewsCount,
          };
          const debouncedInitSlider = debounce(initSlider, 500);
          initSlider(params);
          addEventListener('resize', () => debouncedInitSlider(params));
        }
      }
    } catch (error) {
      console.log('🚀 ~ initReviews ~ error:', error);
    }
  }
};

// shoelace base
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
// Set the base path to the folder where Shoelace is installed
// setBasePath('/node_modules/@shoelace-style/shoelace/dist');
import { ShoelaceComponents } from 'src/assets/js/helpers/constants';
import { waitForElement } from '../../helpers/helpers';

setBasePath('/shoelace');

const shoelaceComponentObjects = {};
export const updateInputsId = async (selector = 'sl-input') => {
  // Query all <sl-input> elements
  const slInputs = document.querySelectorAll(selector);
  if (slInputs?.length) {
    for (const slInput of slInputs) {
      await waitForElement({
        root: document,
        selector,
      });
      // Access the shadow DOM of the <sl-input>
      const inputElement = slInput.shadowRoot.querySelector('input');

      if (inputElement) {
        // Set a custom ID for the input element
        inputElement.id = `custom-input-${Math.random().toString(36).substring(2, 9)}`;
      }
    }
  }
  // // Loop through each <sl-input> and set a custom ID for the input element
  // slInputs.forEach((slInput, index) => {
  //   // Wait for the component to render (shadow DOM to be available)
  //   setTimeout(() => {
  //   }, 100); // Use a small delay to ensure the shadow DOM is ready
  // });
};

export const initShoelaceComponents = async ({ components }) => {
  if (components?.length) {
    for (const component of components) {
      if (!shoelaceComponentObjects[component]) {
        let componentObj;
        switch (component) {
          case ShoelaceComponents.textarea:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/textarea/textarea.js'))?.default;
            break;
          case ShoelaceComponents.select:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/select/select.js'))?.default;
            break;
          case ShoelaceComponents.option:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/option/option.js'))?.default;
            break;
          case ShoelaceComponents.spinner:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/spinner/spinner.js'))?.default;
            break;
          case ShoelaceComponents.tags:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/tag/tag.js'))?.default;
            break;
          case ShoelaceComponents.divider:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/divider/divider.js'))?.default;
            break;
          case ShoelaceComponents.buttonGroup:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/button-group/button-group.js'))?.default;
            break;
          case ShoelaceComponents.radioGroup:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/radio-group/radio-group.js'))?.default;
            break;
          case ShoelaceComponents.radio:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/radio/radio.js'))?.default;
            break;
          case ShoelaceComponents.dropdown:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'))?.default;
            break;
          case ShoelaceComponents.details:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/details/details.js'))?.default;
            break;
          case ShoelaceComponents.tooltip:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/tooltip/tooltip.js'))?.default;
            break;
          case ShoelaceComponents.popup:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/popup/popup.js'))?.default;
            break;
          case ShoelaceComponents.input:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/input/input.js'))?.default;
            updateInputsId();
            break;
          case ShoelaceComponents.checkbox:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/checkbox/checkbox.js'))?.default;
            break;
          case ShoelaceComponents.button:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/button/button.js'))?.default;
            break;
          case ShoelaceComponents.drawer:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/drawer/drawer.js'))?.default;
            break;
          case ShoelaceComponents.dialog:
            componentObj = (await import('@shoelace-style/shoelace/dist/components/dialog/dialog.js'))?.default;
            break;
          case ShoelaceComponents.tab:
            //tab-group
            componentObj = (await import('@shoelace-style/shoelace/dist/components/tab/tab.js'))?.default;
            break;
          case ShoelaceComponents.tabPanel:
            //tab-group
            componentObj = (await import('@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js'))?.default;
            break;
          case ShoelaceComponents.tabGroup:
            //tab-group
            componentObj = (await import('@shoelace-style/shoelace/dist/components/tab-group/tab-group.js'))?.default;
            break;
          default:
            break;
        }
        shoelaceComponentObjects[component] = componentObj;
      }
    }
  }
};

import { checkValidate, validateText } from 'src/assets/js/services/legacy/validation';
import { gridBreakpoints, insertPosition, mainRules } from 'src/assets/js/helpers/constants';
import ejs from 'ejs';

export const getValidationClasses = (form) => {
  if (form.classList.contains('white')) {
    return {
      errorValid: 'errorValid_1',
      errorValidText: 'errorValidText_1',
      errorBlock: 'error_block_1',
      errorNumberValid: 'errorNumberValid_1',
    };
  }
  return {
    errorValid: 'errorValid_2',
    errorValidText: 'errorValidText_2',
    errorBlock: 'error_block_2',
    errorNumberValid: 'errorNumberValid_2',
  };
};

export const clearPreviousErrors = (form, errorValid, errorValidText, errorBlock) => {
  form.querySelectorAll(`.${errorValid}`).forEach((el) => el.classList.remove(errorValid));
  form.querySelectorAll(`.${errorValidText}`).forEach((el) => el.classList.remove(errorValidText));
  form.querySelectorAll(`.${errorBlock}`).forEach((el) => el.remove());
};

export const shouldValidate = (element) => {
  return !element.hasAttribute('disabled') && element.hasAttribute('data-validation');
};

export const validateField = (element, errorValid, errorNumberValid, errorBlock) => {
  let isValid = true;
  const rules = element.getAttribute('data-validation').split('|');

  for (const rule of rules) {
    const isRuleValid = checkValidate(rule, element);
    if (!isRuleValid) {
      isValid = false;
      showError(element, rule, errorValid, errorBlock);
      break;
    }
  }

  if (element.type === 'tel' && (!validateText(element, 4) || element.classList.contains(errorNumberValid))) {
    isValid = false;
    showPhoneError(element, errorValid, errorBlock);
  }

  return isValid;
};

const showError = (element, rule, errorValid, errorBlock) => {
  const message = getErrorMessage(rule);
  if (element.closest('.search-input')) {
    element.closest('.search-input').classList.add(errorValid);
  } else if (element.closest('.date')) {
    element.closest('.date').classList.add(errorValid);
  } else {
    element.classList.add(errorValid);
    element.insertAdjacentHTML('afterend', `<div class='${errorBlock}'>${message}</div>`);
  }
};

const showPhoneError = (element, errorValid, errorBlock) => {
  element.classList.add(errorValid);
  const parent = element.closest('p') || element.closest('.section-form__item');
  parent.insertAdjacentHTML('beforeend', `<div class='${errorBlock}'>Wrong phone format</div>`);
};

const getErrorMessage = (rule) => {
  if (rule.includes('min')) {
    const minLength = rule.match(/\[(.*?)\]/g)[1];
    return `Minimum field length ${minLength} character(s)!`;
  }
  if (rule.includes('max')) {
    const maxLength = rule.match(/\[(.*?)\]/g)[1];
    return `Maximum field length ${maxLength} character(s)!`;
  }
  if (rule.includes('countChars')) {
    const countChars = rule.match(/\[(.*?)\]/g)[1];
    return `Required number of characters in the field ${countChars} character(s)!`;
  }

  return mainRules[rule];
};

export const pasteByInsertPosition = ({
  insertPositionType = insertPosition.beforeend,
  parentElement,
  child,
  callbackAfterPaste,
}) => {
  if (parentElement) {
    const isChildElement = typeof child !== 'string';

    switch (insertPositionType) {
      case insertPosition.afterbegin:
      case insertPosition.beforebegin:
      case insertPosition.afterend:
      case insertPosition.beforeend:
        if (isChildElement) {
          parentElement.insertAdjacentElement(insertPositionType, child);
        } else {
          parentElement.insertAdjacentHTML(insertPositionType, child);
        }
        break;

      case insertPosition.inner:
        if (isChildElement) {
          parentElement.append(child);
        } else {
          parentElement.innerHTML = child;
        }
        break;

      default:
        break;
    }

    callbackAfterPaste && callbackAfterPaste(child);
  }
};

export const getCurrentBreakpoint = () => {
  let breakPoint = 'xxl';

  Object.keys(gridBreakpoints).forEach((key, i, a) => {
    const nextKey = a[i + 1];

    if (nextKey && innerWidth <= gridBreakpoints[nextKey] && innerWidth >= gridBreakpoints[key]) {
      breakPoint = key;
    }
  });

  return breakPoint;
};

export function showLoadingIcon(container) {
  const loadingContainer = container.querySelector('.loading-container');
  if (loadingContainer) {
    loadingContainer.innerHTML = `<img src="${import.meta.env.VITE_STATIC_PATH}/icons/loading.svg" alt="Loading..." class="loading-icon">`;
    loadingContainer.classList.remove('hidden');
  }
}

export function hideLoadingIcon(container) {
  const loadingContainer = container.querySelector('.loading-container');
  if (loadingContainer) {
    loadingContainer.innerHTML = ''; // Remove the loading icon element
    loadingContainer.classList.add('hidden');
  }
}

export const setTabSwitching = ({ container }) => {
  if (container) {
    const tabGroup = container.querySelector('sl-tab-group');
    if (tabGroup) {
      const tabs = [...tabGroup.querySelectorAll('sl-tab')];
      if (tabs?.length) {
        const tabsLength = tabs.length;
        setTimeout(() => {
          let intervalId;
          tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
              clearInterval(intervalId);
              setTimeout(() => {
                initTabsSwitching();
              }, 1);
            });
          });
          const initTabsSwitching = () => {
            let currentTabIndex;
            const initInterval = () =>
              setInterval(() => {
                if (currentTabIndex < tabsLength - 1) {
                  currentTabIndex++;
                } else {
                  currentTabIndex = 0;
                }

                tabGroup.show(tabs[currentTabIndex].getAttribute('panel'));
              }, 5000);

            tabs.forEach((tab, i) => {
              if (tab.getAttribute('active') !== null) {
                currentTabIndex = i;
              }
            });

            intervalId = initInterval();
          };
          initTabsSwitching();
        }, 1000);
      }
    }
  }
};

export const loadEJSTemplate = async ({ templatePath, data = {} }) => {
  try {
    // const templateBasePath = import.meta.env.PROD ? '/static' : '/src';
    const templateBasePath = '/static';
    // Adjust the path based on where your EJS templates are located in `dist`
    const response = await fetch(`${templateBasePath}/${templatePath}`);
    if (!response.ok) throw new Error(`Failed to load template: ${response.statusText}`);
    const template = await response.text();

    return ejs.render(template, {
      env: data,
    });
  } catch (error) {
    console.error('Error loading and render EJS template:', error);
  }
};

export const loadTemplate = async ({ templatePath }) => {
  try {
    // const templateBasePath = import.meta.env.PROD ? '/static' : '/src';
    const templateBasePath = '/static';
    // Adjust the path based on where your EJS templates are located in `dist`
    const response = await fetch(`${templateBasePath}/${templatePath}`);
    if (!response.ok) throw new Error(`Failed to load template: ${response.statusText}`);

    return await response.text();
  } catch (error) {
    console.error('Error loading EJS template:', error);
  }
};
export const getEJSTemplate = async ({ template, data = {} }) => {
  try {
    return ejs.render(template, {
      env: data,
    });
  } catch (error) {
    console.error('Error render EJS template:', error);
  }
};
export const updateInfoLS = ({ formData, LSName }) => {
  const infoObj = (localStorage.getItem(LSName) && JSON.parse(localStorage.getItem(LSName))) || {};

  for (const key in formData) {
    if (Object.prototype.hasOwnProperty.call(formData, key)) {
      infoObj[key] = formData[key];
    }
  }
  localStorage.setItem(LSName, JSON.stringify(infoObj));
};

/**
 * Wait for an element to be added to the DOM or shadow DOM.
 * @param {ShadowRoot | Document} root - The root to observe.
 * @param {string} selector - The selector of the element to wait for.
 * @param {number} [timeout] - The time in milliseconds to wait before rejecting the promise.
 * @return {Promise<HTMLElement | Node>} - A promise that resolves with the element when it is added to the DOM or shadow DOM.
 */
export const waitForElement = ({ root, selector, timeout = 1000 }) => {
  let timeoutId;
  return new Promise((resolve, reject) => {
    let element = root.querySelector(selector);

    if (element) {
      resolve(element);
      return;
    }
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type !== 'childList') {
          return;
        }
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType !== Node.ELEMENT_NODE) {
            return;
          }
          if (node.matches(selector)) {
            observer.disconnect();
            clearTimeout(timeoutId);
            resolve(node);
            return;
          }
          const shadowElement = node?.shadowRoot?.querySelector(selector);
          console.log('🚀 ~ mutation.addedNodes.forEach ~ shadowElement:', shadowElement);

          if (shadowElement) {
            observer.disconnect();
            clearTimeout(timeoutId);
            resolve(shadowElement);
            return;
          }
          if (node.shadowRoot) {
            observer.observe(node.shadowRoot, {
              childList: true,
              subtree: true,
            });
          }
          element = root.querySelector(selector);
          if (element) {
            observer.disconnect();
            clearTimeout(timeoutId);
            resolve(element);
          }
        });
      });
    });

    observer.observe(root, { childList: true, subtree: true });

    timeoutId = setTimeout(() => {
      observer.disconnect();
      reject(new Error(`Element not found: ${selector}`));
    }, timeout);
  });
};

import { hideLoadingIcon, showLoadingIcon } from 'src/assets/js/helpers/helpers.js';

export const initLazyLoadImage = () => {
  const lazyPictures = [...document.querySelectorAll('.lazy-picture')];

  if (lazyPictures.length) {
    lazyPictures.forEach((lazyPicture) => {
      const image = lazyPicture.querySelector('img');

      showLoadingIcon(lazyPicture);

      if (image.complete) {
        hideLoadingIcon(lazyPicture);
      } else {
        image.addEventListener('load', () => {
          hideLoadingIcon(lazyPicture);
        });
      }
    });
  }
};

export const options = {
  method: 'GET',
  headers: {
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Yzc1YWI5OWQxNzhjMTEwOTUyZmRkNCIsImlhdCI6MTY5MDc4NjQ4OX0.G7db8-OXCFQBmmDgCYJYF6pJ9LtUVHaro-mBdOHSnW8',
  },
};
export const base_api_url = 'https://flyer-club.com/';
export const mainRules = {
  required: 'Field is required!',
  mail: 'Incorrect Format',
  min: 'Minimum field length ',
  max: 'Maximum field length ',
  countChars: 'Required number of characters in the field',
  checked: 'Field is required!',
  date: 'Wrong Format!',
  number: 'Field needs to be a Number!',
  equalTotalPrice: 'The amount of credit cards must be equal Total price',
};

export const airDates = [
  {
    id: 0,
    from: '',
    to: '',
  },
];

export const airLlocale = {
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  dateFormat: 'MM/dd/yyyy',
  firstDay: 0,
};

export const baseUrl = '/';

export const airStartDate = new Date();
export const airMinDate = new Date();
export const insertPosition = {
  afterbegin: 'afterbegin',
  afterend: 'afterend',
  beforebegin: 'beforebegin',
  beforeend: 'beforeend',
  inner: 'inner',
};
export const ShoelaceComponents = {
  button: 'button',
  dropdown: 'dropdown',
  divider: 'divider',
  dialog: 'dialog',
  drawer: 'drawer',
  details: 'details',
  tooltip: 'tooltip',
  tags: 'tag',
  checkbox: 'checkbox',
  input: 'input',
  select: 'select',
  option: 'option',
  textarea: 'textarea',
  tab: 'tab',
  tabPanel: 'tab-panel',
  tabGroup: 'tab-group',
  buttonGroup: 'button-group',
  radioGroup: 'radio-group',
  radio: 'radio',
  icons: 'icons',
  spinner: 'spinner',
  popup: 'popup',
};
export const gridBreakpoints = {
  xs: 0,
  sm: 375,
  xsm: 576,
  md: 768,
  xmd: 1024,
  lg: 1240,
  xlg: 1440,
  xl: 1680,
  xxl: 1920,
};
export const preheaderHeight = {
  xs: 36,
  sm: 36,
  xsm: 36,
  md: 41,
  xmd: 41,
  lg: 41,
  xlg: 56,
  xl: 56,
  xxl: 56,
};
export const preheaderBlackFridayHeight = {
  xs: 186,
  sm: 158,
  xsm: 139,
  md: 103,
  xmd: 106,
  lg: 109,
  xlg: 79,
  xl: 79,
  xxl: 79,
};
export const preheaderNewYearHeight = {
  xs: 161,
  sm: 161,
  xsm: 161,
  md: 137,
  xmd: 137,
  lg: 137,
  xlg: 133,
  xl: 133,
  xxl: 94,
};
export const preheaderSpringHeight = {
  xs: 88,
  sm: 88,
  xsm: 88,
  md: 66,
  xmd: 68,
  lg: 68,
  xlg: 48,
  xl: 48,
  xxl: 48,
};

export const headerDecorNewYearHeight = {
  xs: 30,
  sm: 30,
  xsm: 30,
  md: 43,
  xmd: 43,
  lg: 43,
  xlg: 52,
  xl: 52,
  xxl: 62,
};
export const headerHeight = {
  xs: 59,
  sm: 59,
  xsm: 59,
  md: 80,
  xmd: 80,
  lg: 80,
  xlg: 81,
  xl: 81,
  xxl: 81,
};

export const redirectUrls = {
  howToBook: 'https://flyer-club.com/booking/',
  // howToBook: `${baseUrl}booking/#searchflights`,
  // thankYou: `${baseUrl}thankyou?utm_source=website&utm_medium=thanks&utm_campaign=thanks_page`,
  thankYou: `${baseUrl}thankyou`,
  thankYouOrder: `${baseUrl}thankyouorder`,
};
export const inputConfigs = {
  from: {
    placeholder: 'City or Airport',
  },
  to: {
    placeholder: 'City or Airport',
  },
  date: {
    label: 'Date',
    returnLabel: 'Return',
    placeholder: 'Departure',
  },
};
export const bgAddTriggerTypes = {
  SPRINTFUL: 'Sprintful',
  SLIDED_PHONE: 'slided phone',
};
export const trustScoreUrl = import.meta.env.PROD
  ? 'https://flyer-club.com/flightbooking/trustpilot?reviews=true&perpage=20'
  : `${import.meta.env.VITE_STATIC_PATH}/data/reviews.json`;

export const flyTripTypeValues = {
  ROUND_TRIP: 'Round-trip',
  ONE_WAY: 'One-way',
  MULTI_CITY: 'Multi-city',
};
export const quizTypes = {
  SHORT: 'short',
  FULL: 'full',
  FULL_2: 'full2',
  FULL_3: 'full3',
};

export const formTypes = {
  HOW_TO_BOOK: 'how-to-book',
  QUIZ: 'quiz',
  MAIN: 'main',
  MAIN2: 'main-2',
};

export const preloader = document.querySelector('#preloader');
export const loader = preloader.querySelector('#loader');

export const fadeOut = async (element) => {
  element.style.opacity = 0; // Start fading out
  setTimeout(() => {
    element.style.display = 'none'; // After fade out, hide the element
  }, 1000); // 500ms matches the transition duration
};

export const unfade = async (element) => {
  element.style.display = 'block'; // Show the element first
  element.style.opacity = 0; // Start with opacity 0
  setTimeout(() => {
    element.style.opacity = 1; // Fade the element in
  }, 10); // Slight delay to allow the display change to take effect
};

import axios from 'axios';
import { getReviewStars } from 'src/assets/js/services/sliders/initReviews';
import { trustScoreUrl } from 'src/assets/js/helpers/constants';

export const getReviewsSetScore = async () => {
  let reviews;
  try {
    reviews = await axios.get(trustScoreUrl);

    const reviewsElements = document.querySelectorAll('.achievement-stars');

    if (reviewsElements && reviews.data && reviews.data.TrustScore) {
      reviewsElements.forEach((reviewsElement) => {
        const elements = reviewsElement.querySelector('.achievement-stars__elements');
        const score = reviewsElement.querySelector('.achievement-stars__score');
        const count = reviewsElement.querySelector('.achievement-stars__count');

        elements &&
          (elements.innerHTML = getReviewStars({
            stars: reviews.data.TrustScore,
          }));
        score && (score.textContent = reviews.data.TrustScore);
        count && (count.textContent = reviews.data.ReviewsCount);
      });
    }
  } catch (error) {
    console.log('🚀 ~ document.addEventListener ~ error:', error);
  }

  return reviews;
};
